/*
 * Russell Investments
 * IE8-specific base JavaScript file
 */

(function () {

    // Constants
    var OPEN_CLASS = 'open';
    var CLOSE_CLASS = 'close';
    var SEARCH_DISPLAY_CLASS = 'displaySearchContainer';

    var navItems = $('#MainNavigation > ul > li > h2, #UtilityNavigation > ul > li > h6');
    var navItemParents = navItems.closest('li');
    var navItemChildren = navItemParents.find('*');
    var searchNavItem = $('#MainNavigation > ul > li').last();
    var searchContainer = $('#SearchContainer');

    navItems.each(function() {
        var t = $(this);
        var thisParent = t.closest('li');
        t.click(function() {
            if (!thisParent.hasClass(OPEN_CLASS)) {
                navItemParents.removeClass(OPEN_CLASS);
                thisParent.addClass(OPEN_CLASS);
                if (thisParent.is(searchNavItem)) {
                    searchContainer.addClass(SEARCH_DISPLAY_CLASS);
                } else {
                    searchContainer.removeClass(SEARCH_DISPLAY_CLASS);
                }
            } else {
                thisParent.removeClass(OPEN_CLASS);
                searchContainer.removeClass(OPEN_CLASS);
            }
        });
    });

    $('html').click(function(e) {
        var target = $(e.target);
        if (!target.is(navItemChildren)) {
            navItemParents.removeClass(OPEN_CLASS);
        }  
    });

    $(window).scroll(function() {
        if (!navItemParents.hasClass(OPEN_CLASS)) return;
        navItemParents.removeClass(OPEN_CLASS);
        searchContainer.removeClass(SEARCH_DISPLAY_CLASS);
    });

    // Add even and odd classes to table rows
    if ($('table').length) {
        $('tbody').find('tr:odd').addClass('even')
            .find('tr:even').addClass('odd')
            .find('td[rowspan]:not([rowspan="1"])').addClass('multi-rowspan');
    }

    // Secondary nav
    if ($('#SecondaryNavItems').length) {
        var sn = $('#SecondaryNavItems');
        var snScrollWidth = sn[0].scrollWidth;
        var snWidth = sn.width();
        var canScroll = snScrollWidth > snWidth;
        var centerCurrentItem = function() {
            var snCenter = snWidth / 2;
            var currentLi = $('li.current');
            var currentLiCenterPos = currentLi.position().left + (currentLi.width() / 2);
            var diff = currentLiCenterPos - snCenter;
            if (currentLiCenterPos <= snCenter) {
                return;
            }
            sn.scrollLeft(diff);
        };

        if (canScroll) {
            centerCurrentItem();
        }
        sn.addClass('showNavItems');
    }

})();